export default {
  cake: {
    56: '',
    250: '',
    8453: '0xb1a45A38b88b2183f7523Db2E5E5CEa55b050958',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  },
  masterChef: {
    56: '0xD92bc4Afc7775FF052Cdac90352c39Cb6a455900',
    250: '0xC90812E4502D7848E58e53753cA397A201f2e99B',
    8453: '0x9C1677e420AAA1B64Ab273d4c1372AE92D8F85e4',
  },
  sousChef: {
    97: '',
    250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    56: '0xD92bc4Afc7775FF052Cdac90352c39Cb6a455900',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    250: '0x4096068e46eA13DB8E53d46278B68FAAd99efd81',
    8453: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75', // USDC
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0x526568671bFa4e9F60b0120655c66d6B1254a3e5',
    250: '0x911c71cEA5dFe10D666716381a8A976e8dCb7621',
  },
  predictions: {
    56: '0x516ffd7D1e0Ca40b1879935B2De87cb20Fc1124b',
    97: '0x4f3140C74789F1D809420343ea83BcE52B7bbAA5',
  },
  chainlinkOracle: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
}
