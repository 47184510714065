// const AVAX_STABLES = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/stablecoin.tokenlist.json'
// const AVAX_BRIDGE = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/ab.tokenlist.json'
// const AVAX_DEFI = 'https://raw.githubusercontent.com/pangolindex/tokenlists/main/defi.tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  // AVAX_STABLES,
  // AVAX_DEFI,
  // AVAX_BRIDGE,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
