import { MenuEntry } from '@aceofbase/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0xb1a45A38b88b2183f7523Db2E5E5CEa55b050958',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      },
    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: t('Prediction (BETA)'),
  //   icon: 'PredictionsIcon',
  //   href: '/prediction',
  // },
  // {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: t('Info'),
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: 'https://info.spookyswap.finance',
  //     },
  //     {
  //       label: t('Tokens'),
  //       href: 'https://info.spookyswap.finance/tokens',
  //     },
  //     {
  //       label: t('Pairs'),
  //       href: 'https://info.spookyswap.finance/pairs',
  //     },
  //     {
  //       label: t('Accounts'),
  //       href: 'https://info.spookyswap.finance/accounts',
  //     },
  //   ],
  // },

  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Github'),
        href: 'https://github.com/AceOffBase/',
      },
    ],
  },
]

export default config
