import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'ACE-ETH LP',
    lpAddresses: {
      8453: '0xBA0E3bD02Bf7Aac3B35c177250FccEC9a85521d8',
    },
    token: tokens.mainToken,
    quoteToken: tokens.weth,
  },
  {
    pid: 2,
    lpSymbol: 'ETH-USDbC LP',
    lpAddresses: {
      8453: '0xFe2eF6b201C94D721911B471B79C66A7b3Ad3651',
    },
    token: tokens.weth,
    quoteToken: tokens.usdc,
  },
  {
    pid: 3,
    lpSymbol: 'cbETH-ETH LP',
    lpAddresses: {
      8453: '0x99D324952311D5281D33379B099e8D4a9B759665',
    },
    token: tokens.cbeth,
    quoteToken: tokens.weth,
  },
  {
    pid: 6,
    lpSymbol: 'USDC-USDbC LP',
    lpAddresses: {
      8453: '0x61B247bF00e4Ab271c814747C3fb80cd0Ba2e96B',
    },
    token: tokens.usdc2,
    quoteToken: tokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'DAI-USDbC LP',
    lpAddresses: {
      8453: '0xEA983e6795890B725925a1e0A6e928138aF694B0',
    },
    token: tokens.dai,
    quoteToken: tokens.usdc,
  },
  {
    pid: 5,
    lpSymbol: 'axlUSDC-USDbC LP',
    lpAddresses: {
      8453: '0x3835757733c673f7F6F98B7D2Dd5f86AEC39c2a7',
    },
    token: tokens.axlUSDC,
    quoteToken: tokens.usdc,
  },
  {
    pid: 7,
    lpSymbol: 'crvUSD-USDbC LP',
    lpAddresses: {
      8453: '0x42Fd3d1bf6dFac08fDacb82F6f70378311a58854',
    },
    token: tokens.crvUSD,
    quoteToken: tokens.usdc,
  },
  {
    pid: 8,
    lpSymbol: 'wUSDR-USDbC LP',
    lpAddresses: {
      8453: '0xdB0D45B5601D69875326b535B23BA5C1BBe30a2b',
    },
    token: tokens.wUSDR,
    quoteToken: tokens.usdc,
  },
]

export default farms
