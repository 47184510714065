import React from 'react'
import {
  TokenPairImage as UIKitTokenPairImage,
  TokenPairImageProps as UIKitTokenPairImageProps,
  TokenImage as UIKitTokenImage,
  ImageProps,
} from '@aceofbase/uikit'
import { Token } from 'config/constants/types'
import { getAddress } from 'utils/addressHelpers'

interface TokenPairImageProps extends Omit<UIKitTokenPairImageProps, 'primarySrc' | 'secondarySrc'> {
  primaryToken: Token
  secondaryToken: Token
}

const getImageUrlFromToken = (token: Token) => {
  if (token.symbol === 'ETH' ||  token.symbol === 'wETH' || token.symbol === 'WETH') {
    return 'https://s2.coinmarketcap.com/static/img/coins/128x128/1027.png'
  }
  if (token.symbol === 'ACE') {
    return 'https://raw.githubusercontent.com/AceOffBase/assets/main/Assets/logo512.png'
  }
  if (token.symbol === 'USDC') {
    return 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png'
  }

  const address = getAddress(token.address)
  return `/images/tokens/${address}.png`
}

export const TokenPairImage: React.FC<TokenPairImageProps> = ({ primaryToken, secondaryToken, ...props }) => {
  return (
    <UIKitTokenPairImage
      primarySrc={getImageUrlFromToken(primaryToken)}
      secondarySrc={getImageUrlFromToken(secondaryToken)}
      {...props}
    />
  )
}

interface TokenImageProps extends ImageProps {
  token: Token
}

export const TokenImage: React.FC<TokenImageProps> = ({ token, ...props }) => {
  return <UIKitTokenImage src={getImageUrlFromToken(token)} {...props} />
}
