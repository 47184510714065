import { ChainId, Token } from '@aceofbase/ace-base-swap'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xb1a45A38b88b2183f7523Db2E5E5CEa55b050958', // ACE
    18,
    'ACE',
    'ACE',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', 6, 'USDC', 'USD Coin'),
}
export const USDC: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', 6, 'USDC', 'USD Token'),
}

export const WBNB = new Token(
  ChainId.MAINNET,
  '0x4200000000000000000000000000000000000006',
  18,
  'WETH',
  'Wrapped Ether',
)
export const DAI = new Token(ChainId.MAINNET, '0x50c5725949a6f0c72e6c4a641f24049a917db0cb', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', 6, 'USDT', 'USD Coin')

export const ETH = new Token(ChainId.MAINNET, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether')

const tokens = {
  mainToken: {
    symbol: 'ACE',
    address: {
      8453: '0xb1a45A38b88b2183f7523Db2E5E5CEa55b050958',
    },
    decimals: 18,
    projectLink: 'https://aceofbase.xyz',
  },
  eth: {
    symbol: 'ETH',
    address: {
      8453: '0x4200000000000000000000000000000000000006',
    },
    decimals: 18,
    projectLink: 'https://docs.base.org/tokens/list',
  },
  weth: {
    symbol: 'WETH',
    address: {
      8453: '0x4200000000000000000000000000000000000006',
    },
    decimals: 18,
    projectLink: 'https://docs.base.org/tokens/list',
  },
  wftm: {
    symbol: 'WFTM',
    address: {
      56: '',
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    decimals: 18,
    projectLink: 'https://www.fantom.foundation/defi/',
  },
  usdc: {
    symbol: 'USDbC',
    address: {
      8453: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    },
    decimals: 6,
    projectLink: 'https://www.centre.io/',
  },
  usdc2: {
    symbol: 'USDC',
    address: {
      8453: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    },
    decimals: 6,
    projectLink: 'https://www.centre.io/',
  },
  cbeth: {
    symbol: 'cbETH',
    address: {
      8453: '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22',
    },
    decimals: 18,
    projectLink: 'https://docs.base.org/tokens/list',
  },
  dai: {
    symbol: 'DAI',
    address: {
      8453: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
    },
    decimals: 18,
    projectLink: 'https://makerdao.com/',
  },
  axlUSDC: {
    symbol: 'axlUSDC',
    address: {
      8453: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
    },
    decimals: 6,
    projectLink: 'https://axelar.network/',
  },
  crvUSD: {
    symbol: 'crvUSD',
    address: {
      8453: '0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93',
    },
    decimals: 18,
    projectLink: 'https://curve.fi/',
  },
  wUSDR: {
    symbol: 'wUSDR',
    address: {
      8453: '0x9483ab65847A447e36d21af1CaB8C87e9712ff93',
    },
    decimals: 9,
    projectLink: 'https://curve.fi/',
  }
}

export default tokens
