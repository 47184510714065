import { Currency, ETHER, Token } from '@aceofbase/ace-base-swap'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'
import getTokenImageURL from '../../utils/getTokenImageURL'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return ['https://s2.coinmarketcap.com/static/img/coins/128x128/1027.png']
    if (currency?.symbol === "USDC") return ['https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png']
    if (currency?.symbol === "ACE") return ['https://raw.githubusercontent.com/AceOffBase/assets/main/Assets/logo512.png']

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenImageURL(currency)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  // if (currency === ETHER) {
  //   return <BinanceIcon width={size} style={style} />
  // }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
